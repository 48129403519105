<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'staffNumber')"
              :label="$t('hr.employee.employment.staff_number')"
              :rules="{
                required: true,
                numeric: true,
                min_value: 0,
                max_value: 2147483647
              }"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model.number="employeePayload.staffNumber"
                  v-decimal
                  min="0"
                  max="2147483647"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'integrationValue')"
              :label="$t('hr.employee.employment.integration_value')"
              rules="max:255"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.integrationValue"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'branch.name')"
              :label="$t('hr.employee.employment.branch')"
              rules="required"
              :index="2"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <branch-picker
                  v-model="employeePayload.branch"
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'unit.name')"
              :label="$t('hr.employee.employment.unit')"
              rules="required"
              :index="3"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <unit-picker
                  v-model="employeePayload.unit"
                  item-value="id"
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'team.name')"
              :label="$t('hr.employee.employment.team')"
              :index="4"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <team-picker
                  v-model="employeePayload.team"
                  item-value="id"
                  clearable
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'jobInfo.name')"
              :label="$t('hr.employee.employment.job_info')"
              :index="5"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <job-info-picker
                  v-model="employeePayload.jobInfo"
                  item-value="id"
                  clearable
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'employmentType')
                  ? $t(`employment_type.${$helpers.get(employee, 'employmentType').toLowerCase()}`)
                  : null
              "
              :label="$t('hr.employee.employment.employment_type')"
              :index="6"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <employment-types-picker
                  v-model="employeePayload.employmentType"
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'collarType')
                  ? $t(`collar_type.${$helpers.get(employee, 'collarType').toLowerCase()}`)
                  : null
              "
              :label="$t('hr.employee.employment.collar_type')"
              :index="7"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <collar-types-picker
                  v-model="employeePayload.collarType"
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'agency.name')"
              :label="$t('hr.employee.employment.agency')"
              :index="8"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <agency-picker
                  v-model="employeePayload.agency"
                  item-value="id"
                  clearable
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'company.name')"
              :label="$t('hr.employee.employment.company')"
              :index="9"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <company-picker
                  v-model="employeePayload.company"
                  item-value="id"
                  clearable
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'ssn')"
              :label="$t('hr.employee.employment.ssn')"
              rules="max:50"
              :index="10"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.ssn"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="50"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="
                $helpers.get(employee, 'socialSecurityType')
                  ? $t(`social_security_type.${$helpers.get(employee, 'socialSecurityType')}`)
                  : null
              "
              :label="$t('hr.employee.employment.social_security_type')"
              :index="11"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <social-security-type-picker
                  v-model="employeePayload.socialSecurityType"
                  outlined
                  dense
                  style="max-width: 300px"
                  color="tertiary"
                  clearable
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'hiredDate')"
              :label="$t('hr.employee.employment.hired_date')"
              rules="required"
              :index="12"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <date-picker
                  v-model="employeePayload.hiredDate"
                  outlined
                  dense
                  color="tertiary"
                  styles="max-width: 200px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'startedDate')"
              :label="$t('hr.employee.employment.started_date')"
              :index="13"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <date-picker
                  v-model="employeePayload.startedDate"
                  outlined
                  dense
                  color="tertiary"
                  styles="max-width: 200px;"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'allowanceDate')"
              :label="$t('hr.employee.employment.allowance_date')"
              rules="required"
              :index="14"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <date-picker
                  v-model="employeePayload.allowanceDate"
                  outlined
                  dense
                  color="tertiary"
                  styles="max-width: 200px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'researchAndDevelopmentPerson') ? $t('buttons.yes') : $t('buttons.no')"
              :label="$t('hr.employee.employment.research_and_development_person')"
              :index="15"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-switch v-model="employeePayload.researchAndDevelopmentPerson" class="mt-1" />
              </template>
            </profile-field>
          </v-col>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_EMPLOYMENT } from "../query";

  export default {
    name: "EmployeeEmployment",
    props: {
      profileSettings: Object
    },
    components: {
      BranchPicker: () => import("@/components/selectpickers/BranchPicker"),
      UnitPicker: () => import("@/components/selectpickers/UnitPicker"),
      TeamPicker: () => import("@/components/selectpickers/TeamPicker"),
      EmploymentTypesPicker: () => import("@/components/selectpickers/EmploymentTypesPicker"),
      JobInfoPicker: () => import("@/components/selectpickers/JobInfoPicker"),
      AgencyPicker: () => import("@/components/selectpickers/AgencyPicker"),
      CollarTypesPicker: () => import("@/components/selectpickers/CollarTypesPicker"),
      CompanyPicker: () => import("@/components/selectpickers/CompanyPicker"),
      SocialSecurityTypePicker: () => import("@/components/selectpickers/SocialSecurityTypePicker"),
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        staffNumber: null,
        integrationValue: null,
        branch: null,
        unit: null,
        team: null,
        jobInfo: null,
        employmentType: null,
        collarType: null,
        agency: null,
        company: null,
        ssn: null,
        socialSecurityType: null,
        hiredDate: null,
        startedDate: null,
        dayCount: null,
        allowanceDate: null,
        researchAndDevelopmentPerson: false,
        passive: false
      },
      employee: null,
      activeIndex: -1,
      saving: false,
      status: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.ASSIGNMENT);
      }
    },
    watch: {
      "employeePayload.dayCount": {
        handler(v) {
          if (this.status) {
            this.employeePayload.allowanceDate = this.$moment(this.employee.hiredDate)
              .add(v, "days")
              .format("YYYY-MM-DD");
          } else {
            this.status = true;
          }
        }
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updateEmploymentCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.fetchEmployeeEmployment();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload = { ...employee };
        this.employeePayload.branch = this.$helpers.get(employee, "branch.id");
        this.employeePayload.unit = this.$helpers.get(employee, "unit.id");
        this.employeePayload.team = this.$helpers.get(employee, "team.id");
        this.employeePayload.jobInfo = this.$helpers.get(employee, "jobInfo.id");
        this.employeePayload.agency = this.$helpers.get(employee, "agency.id");
        this.employeePayload.company = this.$helpers.get(employee, "company.id");
      },
      async fetchEmployeeEmployment() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_EMPLOYMENT,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee.employment;
              this.employee.dayCount =
                this.$moment(data.employee.employment.hiredDate).diff(
                  this.$moment(data.employee.employment.allowanceDate),
                  "days"
                ) * -1;
              this.setEmployeePayload(data.employee.employment);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeEmployment();
    }
  };
</script>
